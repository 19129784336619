
import React from "react";
import HoneyAsset from "../../images/1 (1).png";
import Community from "../../images/forMSMEBanner.webp";
import FieldSupport from "../../images/socialmarketplace.jpg";
import ProcessingSupport from "../../images/bee-nests-flower-garden.jpg"
import OnBoardSupport from "../../images/young-agronomist-showing-some-information-farmer-smartphone-greenhouse-min.jpg"
import { NavLink } from "react-router-dom";
import businessDiverstification from "../../images/business-growth-bg.jpg";
import CFMarketplace from "../../images/marketplace/CF-marketplace.jpg";
import AgroCareMarketplace from "../../images/marketplace/agrocare-marketplace.jpg"
import BulkMarketplace from "../../images/marketplace/bulk-marketplace.jpg"
import AssetMarketplace from "../../images/buy-assets-marketplace/buy-asset-bg.jpg"
import BillingService from "../../images/marketplace/billing-service.jpg"

import { Row, Col } from "react-bootstrap";

export default function AAMCServices() {
  const services = [
    {
      title: "AAMC",
      image: Community,
      href: "/AAMC",
    },
   
    {
        title: "onMarketplace",
        image: HoneyAsset,
        href: "/onMarketplaceSell",
      },

      // {
      //   title: "Social onMarketplace",
      //   image: FieldSupport,
      //   href: "social-cf-onmarketplace",
      // },

      {
        title: "Social iaaMarketplace",
        image: ProcessingSupport,
        href: "/iaaMarketplace",
      },

      {
        title: "AE onboarding service",
        image: OnBoardSupport,
        href: "/AE-onbarding",
      },
      {
        title: "Billing Services",
        image: BillingService,
        href: "/billing-service",
      },
      {
        title: "Business Diversification Services",
        image: businessDiverstification,
        href: "/business-diversification-service",
        customPropValue: "FP"
      },
          {
        title: "BulkonMarketplace",
        image: BulkMarketplace,
        href: "/bulk-onmarketplace",
      },
         
          {
        title: "Asset Marketplace",
        image: AssetMarketplace,
        href: "/asset-marketplace",
      },
          {
        title: "AgroCare Marketplace",
        image: AgroCareMarketplace,
        href: "/agrocare-marketplace",
      },
      {
        title: "Social CFonMarketplace",
        image: CFMarketplace,
        href: "/social-cf-onmarketplace",
      },
  ];

  return (
    <div className="container for-farmer-section2 px-0 subSectionWrapper pb-0">
      <h2 className="text-center title mb-4 mb-lg-5 container-title">
       Our Services
      </h2>
      <Row className="justify-content-center">
        {services.map((e) => {

          const navLinkProps = {
            to:{pathname: e.href,}

          }

          if (e.customPropValue){
             navLinkProps.to.state = {customProp: e.customPropValue}
          }
          return (
            <Col md={4}>
              <NavLink  {...navLinkProps}>
              <div className="image-container mb-4">

                <img className="" src={e.image} alt="Investment asset" />
                </div>
                <h3 className="text-center container-heading1 mt-0 mb-md-5 mb-4 text-decoration-none">
                  {e.title}
                </h3>
              </NavLink>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
