import React, {lazy, Suspense} from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import "./ForAAMC.css";
import TitleDescription from "../TitleDescription/TitleDescription";
import { Container } from "@material-ui/core";
import AAMCBenefits from "./AAMCBenefits";
import AAMCJourney from "./AAMCJourney";
import AAMCContribution from "./AAMCContribution";
import AgriBusinessChallanges from "../WebSite/AgriBusinessChallanges/AgriBusinessChallanges";
import CTA from "../CTA/CTA";
import ABNNET from "../../images/Farm Enterprise Network Infographic Desktop - FEN Network - 27-08-2024 C2.gif";
import ABNNET1 from "../../images/Farm Enterprise Network Infographic Mobile - FEN Network - 27-08-2024 C2.gif";
import GrowerBenefits from "./GrowerBenefits"
import BrandPricingData from "../pricingTabs/BrandPricingData";
import FarmEnterpriseType from "../FarmEnterpriseType/FarmEnterpriseType";
import FarmEnterpriseCustomerBenefits from "./FarmEnterpriseCustomerBenefits";
import AssetDeveloperPricing from "../pricingTabs/AssetDeveloperPricing";
import BrandBenefits from "./BrandBenefits";
import CFJourney from "./CFJourney";
import OurEcosystem from "./OurEcosystem";

import AAMCServices from "./AAMCServices";

// const MarkdownPreview = lazy(() => import('./AAMCServices'));

export default function ForAAMC() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="AAMCBanner text-white"
        bannerText ="Diversify new revenue streams in your Farm Enterprise"
        bannerbodycopy={[
          // "Introduce new line of business to manage assets and ",
          // <br className="d-lg-block d-none"></br>,
          // "Create your own network of Rural-Entrepreneur",

          "We enable farm enterprises to diversify and expand their operations by ",
          <br className="d-lg-block d-none"></br>,
          "introducing a new line of business of managing investable agri assets ",
          <br className="d-lg-block d-none"></br>,
          "through an approved rural agri-entrepreneur network",
        ]}

        buttonText="Register"
        href="auth/3/signup"
        clicked={() => {
          localStorage.setItem("userType", "AssetDeveloper");
        }}
        // demo="true"
        // bannerbodycopy1="New Revenue Stream
        // | Broadening the Scope
        // | Transform Agri-producers into Agri-entrepreneur"
        // bannerbodycopy={[
        //   "New Revenue Stream | Optimised resource management | Boosts agricultural productivity |",
        //   <br className="d-lg-block d-none"></br>,
        //   "Unlocks opportunities for investors and rural entrepreneurs",
        // ]}
        // buttonText2="Sign in"
        // href2="http://abn.gbrfarming.com/"
        // buttonText3="Sign up"
        // href3="http://abn.gbrfarming.com/auth/3/signup
      />
      <Container>
        <TitleDescription
          classname=""
          title="Discovering Farm Enterprises"
          description={[
            "You are a large-scale farmer with diverse agricultural assets—including dairy cows, poultry, honey colonies, swine, sheep/goats, trees, horticulture, and crops—generating over $5 million in annual revenue. With foundational processes established, you seek to improve your focus on environmental impact, production operations, market access, feed quality, agronomy, and veterinary care.",
            // <br className="d-md-block d-none"></br>,
            // "We help farm enterprises to become  Agri Asset Management Company so that they can assist clients, which can include investors, agri-producers, or agricultural businesses, in effectively managing and generating returns from their agricultural assets.",
          ]}
        />
      </Container>

      <div className="main-wrapper2">
        <AgriBusinessChallanges />
      </div>
      <Container
        className="solutionEcosystem-container  subSectionWrapper"
        id="ExploreABN"
      >
        <h2 className="container-title text-center ">Our Solution</h2>
        <p className="container-para text-center mx-0 mx-md-4">
          System and Process to enable asset light farming infrastructure for farm enterprise and convert them into Agri asset management
          company (AAMC).
        </p>

        <img className="mob-vis1 web-img cursor-initial" src={ABNNET} alt="Infographics"/>
        <img className="mob-vis1 mob-img cursor-initial" src={ABNNET1} alt="Infographics"/>

        {/* <img className="mob-vis1 mob-img cursor-initial" src={ABNNET1} alt="Infographics"/> */}
      </Container>

      <Container>
    {/* <Suspense fallback={<div>Component1 is loading, please wait...</div>}>
    <MarkdownPreview />
</Suspense>



      <MarkdownPreview/> */}
      <AAMCServices />

        <AAMCBenefits />
        <GrowerBenefits/>
        <OurEcosystem/>
        {/* <FarmEnterpriseCustomerBenefits/>
        <BrandBenefits/> */}
        <AAMCContribution />
        {/* <AAMCJourney /> */}
        {/* <CFJourney/> */}
        <div className="">
          <h2 className="container-title text-center ">Farm Enterprise Pricing</h2>
          <AssetDeveloperPricing />
        </div>
        <div className="subSectionWrapper">
          <CTA
            data="present"
            href="/auth/3/signup"
            heading="Want to manage, build & own rural entrepreneur network"
            btnText="Register Now"
            onClick={()=>{
localStorage.setItem("userType", "AssetDeveloper")
            }}
          />
        </div>
      </Container>

      <FooterComponent />
    </>
  );
}
