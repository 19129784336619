import React from 'react';
import { Row, Col } from 'react-bootstrap';
import GoatImg from "../../images/collections/goat.jpg"
import SheepImg from "../../images/collections/sheep.jpg"
import PigImg from "../../images/collections/pig.jpg"
import CowImg from "../../images/collections/cow.jpg"
import HoneybeeImg from "../../images/collections/honeybee.jpg"
import HenImg from "../../images/collections/hen.jpg"


const Collections = ({title}) => {
const collections = [
  {
    img: HoneybeeImg,
    collectionName: "Honey Bee",
  },{
    img: GoatImg,
    collectionName: "Goat ",
  },
  {
    img: SheepImg,
    collectionName: "Sheep",
  },
  
  {
    img: PigImg,
    collectionName: "Pig",
  },
  {
    img: CowImg,
    collectionName: "Dairy Cow",
  },
  {
    img: HenImg,
    collectionName: "Hen",
  },
 ]

  return (
    <div> <div className="container for-farmer-section2 subSectionWrapper px-0">
    {title && (
      <h2 className="text-center title mb-4 mb-lg-5 container-title">
        {title}
      </h2>
    )}
    <Row className="justify-content-center">
      {collections.map((e) => (
        <Col md={3} key={e.title}>
          <div className="image-container mb-4">
            <img className="" src={e.img} alt={e.collectionName} />
          </div>
          <h3 className="text-center  container-heading1 mt-0  mb-4 text-decoration-none">
            {e.collectionName}
          </h3>
        </Col>
      ))}
    </Row>
  </div></div>
  )
}

export default Collections