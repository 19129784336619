import React from "react";
import "./POServices.css";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import AboutSubSection from "../aboutSubSection/AboutSubSection";
import PrivateLabelling from "../../images/Po/status.jpg";
import NewDevelopment from "../../images/Po/paperless.jpg";
export default function CollaborativeFarming(){


    return(
        <>
        <HeaderComponent/>
        <UpdatesBanner 
         className="POBanner text-white"
         bannerText="Collaborative Farming Service"
        //  buttonText="Sign up"
        //  href="http://localhost:3001/auth/undefined/signup"
        /> 
      <div className="for-manufacturers-wrapper">
        
        <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout--mod"
          description="The Agri Contract Farming Service, offered by Farm Enterprises, is a collaborative farming solution for food processing enterprises and brands. It enables secure and reliable sourcing of agricultural products through structured farming agreements, ensuring consistent quality and supply."
          
          title="Agri Contract Farming Service"
          src={PrivateLabelling}
        />
        {/* <AboutSubSection
          className="rowReverseAbout"
          description="Farm enterprise serves as a reliable alternate raw material supplier, offering businesses a stable sourcing solution. With a commitment to quality, we provide a range of materials to meet varying production needs. ${customer} benefit from Farm enterprise's expertise in maintaining a consistent supply of materials."
          title="Alternate Raw Material Supply"
          src={Supplier}
        /> */}
        <AboutSubSection
          className1="aboutBgGrey"
          className="rowReverseAbout"
          description="Farm Enterprise offers the Investable Asset Management (P2P Farming Investment) service as a collaborative farming solution for food processing enterprises and agri-retail investors. This service enables businesses to invest in farm assets, facilitating shared growth and ensuring a reliable supply of agricultural products through managed farming investments."
          title="Investable Asset Management Service"
          src={NewDevelopment}
          buttonContent ="Explore"
          href="/InvestableAssets"
        />
        
</div>

        <FooterComponent/>
        </>
    )
}