import React from "react";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import AgriRetailerChallenges from "./AgriRetailerChallenges";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import TitleDescription from "../TitleDescription/TitleDescription";
import InvestmentServices from "../Investments/InvestmentServices/InvestmentServices";
import { Container } from "react-bootstrap";
import CTA from "../CTA/CTA";
import AgriRetailerBenefits from "./AgriRetailerBenefits";
import AgriRetailerJourney from "./AgriRetailerJourney";
import AgriRetailerContribution from "./AgriRetailerContribution";
import AgriRetailInvestorServices from "./AgriRetailInvestorServices";
import CBOPricing from "../pricingTabs/CBOPricing";

const ExploreAgriRetailer = () => {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="investmentContainer-wrapper"
        bannerTitle="Start Investment Now"
      />
      {/* <div className="main-wrapper2">
        <AgriRetailerChallenges/>
      </div> */}
      <SolutionEcosystem
        type="ARI"
        bold="true"
        bodycopy=" Process for agri-retail investor to fulfill their needs."
      />
      {/* <div className="main-wrapper2 subSectionWrapper mb-0">
        <Container>
          <TitleDescription
          titleClass ="text-start"
          classname2 = "text-start"
            classname="mb-0 mt-0 "
            title="Who are  an Agri-retail Investors"
            description="Agri-retailer investors are key partners in the Farm Enterprise Network (FEN), focusing on investing in agri-assets. Their investments enhance agricultural productivity and sustainability, fostering collaboration and innovation within the network to support a thriving agricultural ecosystem.


            "
          />
</Container>
      </div> */}
      <Container>
        <AgriRetailInvestorServices />
      </Container>

      {/* <AgriBusinessCard /> */}
      <div className="main-wrapper2 subSectionWrapper">
        <h4 className="container-title text-center">
          Agri-retail Investor Benefits
        </h4>
        <AgriRetailerBenefits />
      </div>
      {/* <div className="subSectionWrapper">
        <Container>
        <h4 className="container-title text-center">
              Agri-retail Investor Journey
            </h4>
            <AgriRetailerJourney />
          </Container>
        </div>

          <div className="main-wrapper2">
        <Container>
          <AgriRetailerContribution/>
       </Container>
          </div> */}

      {/* <div className="subSectionWrapper pt-0">
        <Container>
              <CTA
              //  data="present"
               href="/auth/3/signup"
               heading="Invest Now: Grow Your Wealth with Strategic Asset Investments!"
               btnText="Register now for free"
               onClick={()=>{
                 localStorage.setItem("userType", "AssetOEM")
                             }}
              />
              </Container>
            </div> */}

      {/* <Container>
<h2 className="container-title text-center "> Pricing</h2>

<CBOPricing/>

          </Container> */}

      <FooterComponent />
    </>
  );
};

export default ExploreAgriRetailer;
