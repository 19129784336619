import React from 'react'
import HeaderComponent from '../WebSite/Header/HeaderComponent'
import FooterComponent from '../WebSite/Footer/FooterComponent'
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner'
import TitleDescription from "../TitleDescription/TitleDescription";
import { Container } from '@material-ui/core';
import NetworkOperatorChallenges from './NetworkOperatorChallenges';
import SolutionEcosystem from '../WebSite/SolutionEcosystem/SolutionEcosystem';
import NetworkOperatorBenefits from './NetworkOperatorBenefits';
import CTA from "../CTA/CTA"
export default function ForNetworKOperators() {
  return (
    <><HeaderComponent/>
<UpdatesBanner
        className="forManufactures"
        bannerTitle={[
          "Introducing FEN to create an ecosystem around agribusiness",
        ]}
        bannerbodycopy1 ={["GBR open endless possibilities for Telecom Companies  and FINANCIAL INSTITUTIONS", <br className="d-lg-block d-none"></br> ,"to Grow Big and Reap from the Agri-Business Network"]}
        bannerbodycopy="OWN | OPERATE | MANAGE
          "
        buttonText2="OWN FEN NOW"
        href2="
        http://gbrfarming.in/EnterprisePortal/register.html?src=registerReq
     "
        // href1="/auth/3/signup"
        // buttonText1="Join"
        // onClick={() => {
        //   localStorage.setItem("abnUser", "FOOD-PROCESSOR");
        // }}
      /> 
      <Container>
        <TitleDescription
          classname=""
          title="Who are Network Operators"
          description={[
"Network operators, encompassing both telecom operators and financial institutions, are organizations responsible for managing and maintaining telecommunications networks. These networks, which include telephone networks, mobile networks, internet service provider (ISP) networks, and data center networks, are vital for connectivity and service provision. Network operators deploy, maintain, troubleshoot, and secure network infrastructure, ensuring smooth functionality. Telecom operators, ISPs, government agencies, and large enterprises with extensive networking infrastructure are examples of network operators, playing a crucial role in global communication system reliability and efficiency."          ]}
        />
      </Container>

      <div className="main-wrapper2">
        <NetworkOperatorChallenges />
      </div>
      <SolutionEcosystem
        type="NO"
        bodycopy="system and process to create a new revenue stream through technical intervention."
      />
      <div className='subSectionWrapper pb-0'>
           <Container className="subSectionWrapper">
          <NetworkOperatorBenefits />
        </Container>
        </div>

        <Container>
      {" "}
      {/* <AgriEntrepenurBenefits /> */}
      <div className="subSectionWrapper">
      <CTA
      // secondBtn= "true" 
      // href3="/agri-business"
      // btnText2= "Explore Agri Business"
       data="present"
        href2="
        http://gbrfarming.in/EnterprisePortal/register.html?src=registerReq"
        heading="Create an account to Own & Operate FEN Network"
        btnText="Join Now"
      />
    </div>
    </Container>
         <FooterComponent/>
    </>
  )
}
