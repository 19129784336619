import React from "react";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import { Container } from "@material-ui/core";
import AgriFoodManufacturersBenefits from "./AgriFoodManufacturersBenefits";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import AgriFoodmanufacturersNeeds from "./AgriFoodmanufacturersNeeds";
import AgriFoodManufacturersJourney from "./AgriFoodManufacturersJourney";
import AgriFoodManufacturerersServices from "./AgriFoodManufacturerersServices";
import BrandPricingData from "../pricingTabs/BrandPricingData";
import CTA from "../CTA/CTA";
import TitleDescription from "../TitleDescription/TitleDescription";
import AgriEntreprenurBenefits from "./AgriEntreprenurBenefits";
import ContractManufactureTypes from "../ContractManufactureTypes/ContractManufactureTypes";
import AgriFoodManufacturerCustomerBenefit from "./AgriFoodManufacturerCustomerBenefit";
import AgriFoodManufacturerContribution from "./AgriFoodManufacturerContribution";
import FEServices from "./FEServices";
import Collections from "../Collections/Collections";
export default function ForAgriFoodManufacturers() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="forManufactures"
      


        bannerText={[
          "Want to be part of Farm Enterprise Network to source ",  <br className="d-lg-block d-none"></br>, "high-quality, authentic, and traceable agricultural raw products ",
          <br className="d-lg-block d-none"></br>,
          "  directly from verified Agri-Suppliers ",
        ]}
        bannerbodycopy="End to End Traceability | Food Safety | Assured Quality | Supply chain efficiency | Best Pricing | Global Supplier Discovery | Contract Farming"
        // buttonText="Explore Community"
        // href="/partners"
        // href1="/auth/3/signup"
        // buttonText1="Join"
        // onClick={() => {
        //   localStorage.setItem("abnUser", "FOOD-PROCESSOR");
        // }}
      />

      {/* <h4 class="container-title mb-3">Who are SMEs</h4>
          <p class="container-para px-auto px-md-5">
            Agri-SMEs are small and medium-sized enterprises operating in the
            agricultural sector, encompassing farming, agro-processing, and
            related activities. They play a significant role in food production,
            rural development, and employment generation.
          </p> */}
      {/* <Container>
        <TitleDescription
          classname=""
          title="Who are Food Processing Enterprises"
          description={[
            "Food Processing Enterprises are involved in processing agricultural products into consumable goods, playing a crucial role in the production and transformation of raw materials such as crops and livestock into various food products. Examples include those producing packaged foods, beverages, meat, dairy, and processed agricultural goods.",
            // <br className="d-md-block d-none"></br>,
            // "We help farm enterprises to become  Agri Asset Management Company so that they can assist clients, which can include investors, agri-producers, or agricultural businesses, in effectively managing and generating returns from their agricultural assets.",
          ]}
        />
      </Container> */}
      {/* <ContractManufactureTypes/> */}
      {/* <div className="main-wrapper2">
        <AgriFoodmanufacturersNeeds />
      </div> */}
      {/* <SolutionEcosystem
      bold="true"
        type="AF"
        bodycopy="System and process to scale an existing food processing enterprise by creating a network of micro-small contract manufacturers and a decentralized processing model.
        "
      /> */}
      <div className="main-wrapper2">
        <Container>
        <AgriFoodManufacturerersServices />
        </Container>
      </div>
      <div className="main-wrapper2">
        <Container>
        <FEServices />
        </Container>
      </div>

      <Collections title="Current Agricultural Asset Supported"/>

      <div className="MSMEBenfitJourney">
        <Container>
        <AgriFoodManufacturersBenefits />
        </Container>
        {/* <AgriEntreprenurBenefits />
        <Container>
        <AgriFoodManufacturerCustomerBenefit/>
        <AgriFoodManufacturerContribution/>
        </Container>
        <AgriFoodManufacturersJourney />  */}
      </div>

  
      <FooterComponent />
    </>
  );
}
