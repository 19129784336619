
import React from "react";
import CBN from "../../../images/gbrCBN/CBNBanner.webp";
import BillingService from "../../../images/marketplace/billing-service.jpg";
import Advertisement from "../../../images/people-using-ads-digital-marketing-concept-online-advertisement-ad-website-social-media-customer-min.jpg"
import { Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";

export default function GBRFarmerService() {
  const services = [
    {
        title: "AgroCare Marketplace",
        image: CBN,
        href: "/agrocare-marketplace",
      },
      {
        title: "Asset Marketplace",
        image: CBN,
        href: "/asset-marketplace",
      },
      {
        title: "Advertisement",
        image: Advertisement,
        href: "/advertisement",
      },
      {
        title: "Billing Services",
        image: BillingService,
        href: "/billing-service",
      },
   


      
  ];

  return (
    <div className="container for-farmer-section2 px-md-0 subSectionWrapper pb-0">
      <h2 className="text-center title mb-4 mb-lg-5 container-title">
      Our Services
      </h2>
      <Row className="justify-content-center">
        {services.map((e) => {
          return (
            <Col md={4}>
              <NavLink to={e.href}>
              <div className="image-container mb-4">

                <img className="" src={e.image} alt="Investment asset" />
                </div>
                <h3 className="text-center container-heading1 mt-0 mb-md-5 mb-4 text-decoration-none">
                  {e.title}
                </h3>
              </NavLink>
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
